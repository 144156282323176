.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;

  &.block {
    display: block;
    margin: 0 auto;
  }

  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  &.dark {
    &:after {
      border-color: #333 transparent #333 transparent;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
