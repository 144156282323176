@import '~antd/dist/antd.css';

.loaded #root {
    opacity: 1;
}

#root,
.ant-layout,
.ant-tabs,
.ant-tabs-content,
.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-table,
.ant-table-content,
.ant-table-placeholder {
  height: 100%;
}

.ant-layout-header {
    color: #fff;
    padding: 0 16px;
}

.ant-layout-content {
    overflow: auto;
}

.content {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inset-wrap {
    margin: 16px;
    padding: 24px;
    background-color: #fff;
    border-radius: 5px;
    height: calc(100% - 32px);
    box-sizing: border-box;
    overflow-y: auto;
}

.ant-layout-sider {
    overflow-y: auto;

    .ant-form-item-label label {
        color: #fff;
    }
}

.ant-input-group-wrapper {
    vertical-align: middle;
}

.ant-tabs-bar {
    background-color: #fff;
}

.ant-tabs-content {
    position: relative;
}

.ant-tabs .ant-tabs-left-content {
    padding-left: 0;
}

.ant-tabs-tabpane {
    transition: opacity .2s ease, transform .2s ease;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    overflow-y: auto;

    &-active {
        opacity: 1;
        transform: translateY(0);

        & ~ .ant-tabs-tabpane {
            transform: translateY(50%);
        }
    }
}

.ant-tabs-left-content > .ant-tabs-tabpane-inactive {
    opacity: 0;
    display: block;
    pointer-events: none;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    width: 4px;
}

.ant-form-item {
    margin-bottom: 0;

    & + .ant-collapse {
        margin-top: 24px;
    }
}

.ant-collapse .ant-form-item label {
    color: #333;
}

.ant-form-item-label label:after {
    display: none;
}

.ant-list-item-meta-content {
    width: 100%;
}
