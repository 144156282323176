.input-addon {
    color: #fff;
    display: inline-block;
    height: 32px;
    line-height: 2;
    vertical-align: middle;
    margin: 0 10px;
}

    .ant-layout-sider .input-addon {
        color: #fff;
    }
