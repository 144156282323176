.fav-button {
    opacity: 0.2;
    transition: opacity .2s ease;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    margin-right: -32px;

    &.exist {
        opacity: .5;
    }

    &:hover {
        opacity: 1;
    }
}
