.align-center {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .center-wrap {
        min-width: 50vw;
    }
}
